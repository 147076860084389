import React from 'react'
import { motion } from "framer-motion"
import { fadeSlow, revealInOut, scaleReveal } from "../helpers/transitionHelper"
import {Helmet} from "react-helmet";
import { graphql, Link } from 'gatsby'

const ContactPage = ({data, childAnimationDelay}) => {
  return (
    <motion.section
      initial="initial"
      animate="enter"
      exit="exit"
      className="contact-page"
    >    
      <Helmet>
        <title>Contact - {data.sanitySiteSettings.title}</title>
      </Helmet>
      <motion.div variants={fadeSlow}>
        <motion.div variants={{
          enter: { transition: { 
            staggerChildren: .1,
            delayChildren: childAnimationDelay
          }}
        }}>
          
            <motion.h1 variants={revealInOut}>contact</motion.h1>

            <motion.div variants={scaleReveal} className="contact-image">
                <img src="https://cdn.sanity.io/images/rhm4oa3y/production/0d65d0af9c75b2c97d9040e6cf8bb56690dc60e1-4256x2832.jpg?w=600" alt=""/>
            </motion.div>

            <motion.div className="contact-links" variants={{
                enter: { transition: { 
                    staggerChildren: .1,
                }}
            }}>

                {/* <motion.div variants={revealInOut}>Helen English</motion.div> */}
                {/* <motion.div variants={revealInOut}>P: <a href="tel:0405607272">0405-607-272</a></motion.div> */}
                <motion.div variants={revealInOut}>E: <a href="mailto:creative-ageing@newcastle.edu.au">creative-ageing@newcastle.edu.au</a></motion.div>
                
                
                
            </motion.div>
        </motion.div>
      </motion.div>
    </motion.section>
  )
}

export default ContactPage

export const query = graphql`
  query ContactQuery {
    sanitySiteSettings {
      title
    }
  }
`